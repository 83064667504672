<template>
  <div/>
</template>

<script>
import { useUserStore } from '../store';
export default {
  async created() {
    this.loggedIn = useUserStore().isLoggedIn === null ? false : true;
    if (!this.loggedIn) {
      this.$router.push('/');
    } else {
        // logout 
        useUserStore().reset();
        this.$router.push('/');
    }
  },
}
</script>

<style>

</style>