import Vue from 'vue';
import { createPinia, defineStore } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Axios from 'axios';
Axios.defaults.withCredentials = true;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
Vue.use(pinia);

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      token: '',
      user: null,
    }
  }, actions: {
    setToken(value) {
      this.token = value;
    },
    setUser(value) {
      this.user = value;
    },
    reset() {
      this.token = '';
      this.user = null;
    },
    login(token, user) {
      this.token = token;
      this.user = user;
    }
  }, getters: {
    isLoggedIn(state) {
      return state.user !== null;
    },
    getUser(state) {
      return state.user;
    }
  },
  persist: {
    enabled: true,
    storage: sessionStorage,
  }
});
