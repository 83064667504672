// =========================================================
// * Vue Material Dashboard - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import VueRouter from "vue-router";
import App from "./App";

// router setup
import routes from "./routes/routes";
// store setup
import { useUserStore } from "./store";
const userStore = useUserStore();

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
});

router.beforeEach((to, _, next) => {
  console.log("requested " + to.name);
  console.log("is logged in? " + userStore.isLoggedIn);
  const isLoggedIn = userStore.isLoggedIn;
  if (to.meta.requiresAuth && !isLoggedIn && to.name !== "Login") {
    next({ name: "Login" });
    // redirect to dashboard, if trying to access login or register when logged in
  } else if (!to.meta.requiresAuth && isLoggedIn) {
    next({ path: "/p/view/0" });
  } else if (to.meta.requiresAuth && isLoggedIn) {
    next();
  } else {
    next();
  }
});

Vue.use(VueMaterial);
Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  // store,
});
