<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/p/view/0">
        <md-icon>mark_email_unread</md-icon>
        <p>Latest Edition</p>
      </sidebar-link>
      <sidebar-link to="/p/archive">
        <md-icon>mark_as_unread</md-icon>
        <p>Newsletter Archive</p>
      </sidebar-link>
      <sidebar-link to="/p/photoarchive">
        <md-icon>perm_media</md-icon>
        <p>Photomagazine Archive</p>
      </sidebar-link>
      <sidebar-link to="/p/logout" class="active-pro">
        <md-icon>login</md-icon>
        <p>Logout</p>
      </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <!--
      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>
      -->

      <dashboard-content></dashboard-content>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
//import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu
    //FixedPlugin
  },
  data() {
    return {
      loggedIn: false,
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/bg.png")
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`
      };
    }
  }
};
</script>
<style scoped>
.footer {
    position: inherit !important;
    padding: 0 !important;
}
</style>