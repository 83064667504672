<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 v-if="id!=0" class="title">Read</h4>
            <h4 v-else class="title">Latest edition</h4>
            <p class="category">
              Read the newsletter below.<br />
              Tip: Click or touch once for 3 levels
              of zoom. You can also use the button to the right in order to
              download a PDF file, or use the viewer below.
            </p>
          </md-card-header>
          <md-card-content>
            <flipbook class="flipbook" :pages="pages" :pagesHiRes="pagesHq" style="top: 0px !important" v-slot="flipbook">
              <div class="md-layout md-gutter md-alignment-center action-bar">
                <md-button @click="flipbook.flipLeft">
                  <md-tooltip md-direction="top">Scroll to the left</md-tooltip>
                  <md-icon>arrow_back</md-icon>
                </md-button>
                <md-button @click="flipbook.zoomIn">
                  <md-tooltip md-direction="top">Zoom in</md-tooltip>
                  <md-icon class="larger-icons">zoom_in</md-icon>
                </md-button>
                <md-button @click="flipbook.zoomOut">
                  <md-tooltip md-direction="top">Zoom out</md-tooltip>
                  <md-icon>zoom_out</md-icon>
                </md-button>
                <md-button @click="flipbook.flipRight">
                  <md-tooltip md-direction="top">Scroll to the right</md-tooltip>
                  <md-icon>arrow_forward</md-icon>
                </md-button>
                <md-button v-on:click="download()">
                  <md-tooltip md-direction="top">Download PDF</md-tooltip>
                  <md-icon>cloud_download</md-icon>
                </md-button>
              </div>
            </flipbook>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Flipbook from "flipbook-vue/dist/vue2/flipbook.cjs";
import AuthService from "@/services/AuthService.js";
import { useUserStore } from "../store";

export default {
  data() {
    return {
      pages: [null],
      pagesHq: [null],
    };
  }, props: {
    id: String,
  },

  components: {
    Flipbook,
  },
  watch: { 
    id: function(newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.loadEdition();
      }
    }
  },

  async created() {
    this.loadEdition();
  },

  methods: {
    async loadEdition() {
      try {
        const response = await AuthService.getBook(this.id);
        this.pages = response.pages;
        this.pagesHq = response.pagesHq;
      } catch (e) {
        useUserStore().reset();
      }
    },

    downloadFile(filePath) {
      var link = document.createElement("a");
      link.href = filePath;
      link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
      link.click();
    },
    download() {
      this.downloadFile(AuthService.getDownloadURL(this.id));
    },
  },
};
</script>

<style>
.flipbook {
  height: 120vh;
}


.action-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
