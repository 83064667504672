import axios from 'axios';
import * as Msal from 'msal';

const url = `${process.env.BACKEND || "https://newsletter.in4md-service.de"}/api/v1/`;

export default {
    randomString(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charLength));
            }
            return result;
    },

    login(credentials) {
        return axios
        .post(`${url}users/login`, credentials)
        .then(function(response) {
            return {
                user: response.data
            }
        });
    },

    getBook(id) {
        // Returns book information for requested ID
        return axios.get(`${url}newsletter/book/${id}`)
        .then(response => {
            let data = response.data;
            data.pages = [null];
            data.pagesHq = [null];
            for (let i = response.data.first_page; i <= response.data.last_page; i++) {
                data.pages.push(`${url}newsletter/book/${response.data.id}/${i}`);
                data.pagesHq.push(`${url}newsletter/book/${response.data.id}/hq/${i}`);
            }
            return data;
        });
    },

    getAllNewsletters() {
        return axios.get(`${url}newsletter/all`)
        .then(response => response.data.reverse())
    },

    getDownloadURL(id) {
        return `${url}newsletter/${id}`;
    },

    getNewsletter(id) {
        return axios.get(`${url}newsletter/${id}`)
        .then(response => response.data)
    },

    openAuth_service(method) {
        let clientId = "";
        let tenantId = "";

        // first value is in4MD Service, second the analysts
        if (method === 4) {
            clientId = "9bac9310-d7b3-4d91-bdcc-2fc7ea097f12";
            tenantId = "5b0fd754-bbbe-45ed-8c4a-d39f92a252a5";
        } else if (method === 5) { // TADT
            // the analysts
            clientId = "f04ff7ed-9110-4444-8f0b-ee3eb41280ba";
            tenantId = "295a551d-603c-403f-8d91-c4fa3ea34faa";
        } else {
            // s4campus
            clientId = "3ed30a58-6430-4418-8843-1cc4f1fc4c65";
            tenantId = "5eb824d8-5eac-4f2d-8a87-b41f7a968c09";
        }
        // Config object to be passed to Msal on creation
        const msalConfig = {
            auth: {
            clientId: clientId,
            redirectUri: "https://Newsletter.in4MD-Service.de/#/login",
            authority: `https://login.microsoftonline.com/${tenantId}`,
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            }
        };

        return new Msal.UserAgentApplication(msalConfig);
    },

    verifyJWT(jwt) {
        const credentials = {
            username: jwt,
            // method does not matter for backend, as we check against two
            // authorities anayways
            method: 4
        }
        return axios
        .post(`${url}users/login`, credentials)
        .then(function(response) {
            return {
                user: response.data
            }
        });
    },

    latestNewsletterAvailible() {
        return axios
        .head(`${url}newsletter/7Q4JS45jPY9WqEZt5dmA7Q4JS45jPY9WqEZt5dmA`)
        .then(function(response) {
            return response;
        });
    },

    getLatestPublic() {
        return `${url}newsletter/7Q4JS45jPY9WqEZt5dmA7Q4JS45jPY9WqEZt5dmA`;
    }
}
