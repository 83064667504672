import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import Archive from "@/pages/Archive.vue";
import PictureArchive from '@/pages/PictureArchive.vue';
import View from "@/pages/View.vue";
import Index from "@/pages/Index.vue";
import Component from "@/pages/Component.vue";
import PublicAsset from "@/pages/PublicAsset.vue";

import Login from "@/pages/Login.vue";
import Logout from "@/pages/Logout.vue";

const routes = [
  {
    path: "/",
    component: Index,
    redirect: "login",
    children: [
      {
        name: "Login",
        path: "login",
        component: Login,
        meta: {
          requiresAuth: false
         }
      }, {
        path: '/ctfof0dlbk6q4cit',
        component: PublicAsset,
        name: "Public Newsletter",
        meta: {
          requiresAuth: false
        }
      }
    ]
  }, {
    path: "*",
    component: Component,
    name: "Redirect",
  }, {
    path: "/dashboard",
    redirect: "/p/view/0",
    component: DashboardLayout,
    children: [
      {
        path: "/p/archive",
        name: "Archive",
        component: Archive,
        meta: {
          requiresAuth: true
         }
      }, {
        path: "/p/photoarchive",
        name: "Photoarchive",
        component: PictureArchive,
        meta: {
          requiresAuth: true
        }
      }, {
        path: "/p/logout",
        component: Logout,
        name: "Logout",
        meta: {
          requiresAuth: true
        }
      }, {
        path: "/p/view/:id",
        name: "View",
        props: true,
        component: View,
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

export default routes;
