<template>
  <div class="wrapper wrapper-full-page full-page">
    <div class="page-header header-filter login-page" :style="headerStyle">
      <div class="container md-offset">
        <div class="md-layout text-center">
          <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
            <div class="md-card md-card-login md-theme-default">
              <div class="md-card-header md-card-header-green">
                <h3 class="title">Login</h3>
              </div>
              <div class="md-card-content">
                <h4>
                  Verifying response from Microsoft, please wait...
                </h4>
                <div class="alert alert-danger" v-if="error">
                  <span>
                    <b>Error -</b>
                    {{ error }}
                  </span>
                </div>

                <md-progress-spinner md-mode="indeterminate" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <content-footer class="landing-footer"></content-footer>
  </div>
</template>

<script>
import ContentFooter from "@/pages/Layout/ContentFooter.vue";
import AuthService from "@/services/AuthService.js";
import { useUserStore } from '../store';

export default {
  components: {
    ContentFooter
  },
  data() {
    return {
      error: "",
      bgImage: require("@/assets/img/bg.png"),
      loggedIn: false,
      store: useUserStore(),
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.bgImage})`
      };
    }
  },
  async created() {
    let id_token = window.location.hash;
    id_token = id_token.split('#/id_token=')[1];
    id_token = id_token.split('&')[0];
    try {
      const response = await AuthService.verifyJWT(id_token);
      if (response.user.username && response.user.username.length > 0) {
        // success -> go to dashboard
        const token = response.user.username;
        const user = response.user;

        this.store.login(token, user);
        this.$router.push("/p/view/0");
      } else {
        throw "Error while decrypting Data!"
      }
    } catch (e) {
      this.error = `Could not verify token. Error Details: ${e}. Redirecting to login page...`;
      setTimeout(() => this.$router.push({ path: '/login' }), 5000);
    }
  },
};
</script>

<style>
.landing-page {
  height: 100%;
  min-height: 100vh;
}

.landing-page.page-header>.container {
  padding: 0;
}

.landing-footer {
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: #fff;
}
</style>