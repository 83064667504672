<template>
  <div style="min-height: 100% !important" class="wrapper wrapper-full-page full-page">
    <div class="page-header header-filter login-page" :style="headerStyle">
        <!-- login page or registration page or loading token page -->
        <router-view></router-view>
    </div>
  <content-footer class="landing-footer"></content-footer>
</div>
</template>

<script>
import ContentFooter from "@/pages/Layout/ContentFooter.vue";
export default {
  components: {
      ContentFooter
  },
  data() {
    return {
      bgImage: require("@/assets/img/bg.png"),
      loggedIn: false,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.bgImage})`
      };
    }
  },
  async created() {
  }
}
</script>

<style>
</style>
