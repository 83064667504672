<template>
  <div class="container md-offset">
    <div class="md-layout text-center">
      <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
        <div class="md-card md-card-login md-theme-default">
          <div class="md-card-header md-card-header-green">
            <h3 class="title">Login</h3>
            <md-progress-spinner v-show="loading" md-mode="indeterminate" />
            <div>
            <md-menu md-direction="bottom-start">
              <md-button md-menu-trigger class="md-just-icon md-round md-theme-default" @click="changeLoginMethod(1)">
                <img src="@/assets/img/ucc_logo.png" />
              </md-button>
              <md-button md-menu-trigger class="md-just-icon md-round md-google md-theme-default" @click="changeLoginMethod(3)">
                <img src="@/assets/img/campus_logo.png" />
              </md-button>
              <br />
              <md-button md-menu-trigger class="md-just-icon md-round md-google md-theme-default" @click="changeLoginMethod(4)">
                <img src="@/assets/img/service_logo.png" />
              </md-button>
              <md-button md-menu-trigger class="md-just-icon md-round md-google md-theme-default" @click="changeLoginMethod(5)">
                <img src="@/assets/img/analyst_logo.png" />
              </md-button>
              </md-menu>
            </div>
          </div>
          <div class="md-card-content" v-if="loginMethod==99">
            <h4>Please choose your company above to login</h4>
          </div>
          <div class="md-card-content">
            <h4 v-if="loginMethod == 1">Login at SAP UCC Magdeburg</h4>
            <h4 v-if="loginMethod == 3">Login at S4Campus AG</h4>
            <h4 v-if="loginMethod == 4">Login at in4MD Service GmbH</h4>
            <h4 v-if="loginMethod == 5">Login at The Analysts Data Technology GmbH</h4>
            <div v-if="loginMethod==1">
              <md-field>
                <label>Username</label>
                <md-icon class="md-theme-default">person</md-icon>
                <md-input v-model="username" type="text" :disabled="loading == true" @keyup.native.enter="login" />
              </md-field>
              <md-field>
                <label>Password</label>
                <md-icon>lock</md-icon>
                <md-input v-model="password" type="password" :disabled="loading == true" @keyup.native.enter="login"/>
              </md-field>
            </div>
        <div class="alert alert-danger" v-if="error">
          <span>
            <b>Error -</b>
            {{ error }}
          </span>
          </div>
          </div>
          <div class="md-card-actions md-alignment-right" v-if="loginMethod==1">
            <md-button
              @click="login"
              @keyup.enter="login"
              :disabled="loading == true"
              class="md-simple md-success md-lg md-theme-default"
            >Login</md-button>
          </div>
          <div class="md-card-actions" v-if="loginMethod>1">
            <md-button
              @click="login"
              :disabled="loading == true"
              class="md-simple md-success md-lg md-theme-default"
            >Login using Microsoft <i class="fab fa-microsoft" /></md-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "@/services/AuthService.js";
import { useUserStore } from "../store";

export default {
  components: {},
  bodyClass: "login-page",
  data() {
    return {
      loginMethod: 99,
      username: null,
      password: null,
      loading: false,
      error: null,
      store: useUserStore(),
    };
  },
  props: {},

  methods: {
    changeLoginMethod(id) {
      this.loginMethod = id;
      this.error = "";
    },
    async login() {
      this.loading = true;
      if (this.loginMethod !=  1) {
        // redirect to Microsoft
        // Config object to be passed to Msal on creation
        const msal = AuthService.openAuth_service(this.loginMethod);
        const loginRequest = { scopes: ["openid"] };
        await msal.loginRedirect(loginRequest);
        return;
      }

      try {
        const credentials = {
          username: this.username,
          password: this.password,
          method: this.loginMethod,
        };
        const response = await AuthService.login(credentials);
        if (response.user.username && response.user.username.length > 0) {
          // success -> go to dashboard
          const token = response.user.username;
          const user = response.user;

          this.store.login(token, user);
          this.$router.push("/p/view/0");
        } else {
          throw "Error!";
        }
      } catch (e) {
        this.error = "Could not login - invalid credentials? Details: " + e;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="css">
.md-button.md-fab, .md-button.md-just-icon {
  padding: 10px;
  border-radius: 40px! important;
  width: 80px !important;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
}

.md-button {
  margin: 2px 3px 3px 3px !important;
}

.md-field label {
  left: 36px !important;
}

.page-header {
  display: flex;
}

.page-header {
  background-image: url("/@/assets/img/bg.png");
  background-size: cover;
  background-position: top;
}

.md-card-login {
  max-width: 320px;
}

.page-header > .container {
  padding-top: 70px;
  padding-bottom: 160px;
}

.full-page .container {
  width: 100%;
}

.text-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.header-filter {
  position: relative;
  height: 100%;
  min-height: 100vh;
}

.md-card-login {
  padding-top: 0;
}

.md-offset {
  max-width: 1140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: auto !important;
}
</style>
