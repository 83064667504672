<template>
  <div class="container md-offset">
    <div class="md-layout text-center">
      <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
        <div class="md-card md-card-login md-theme-default">
          <div class="md-card-header md-card-header-green">
            <h3 class="title">Newsletter</h3>
          </div>
          <div class="md-card-content">
            <div class="alert alert-danger" v-if="error">
              <span>
              <b>Sorry -</b>
              {{ error }} 😔
              </span>
            </div>
            <div class="alert alert-info" v-else>
            Your download should begin automatically
            </div>
            <h4>You can click the button below to visit the archive</h4>
            <md-button class="md-primary" v-on:click="goBack()"><i class="fas fa-sign-in-alt" /></md-button>
        </div>
      </div>
    </div>
  </div>
</div>
  </template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
    data() {
        return {
            error: "",
        }
    },
    async created() {
        try {
            await AuthService.latestNewsletterAvailible();
            const url = AuthService.getLatestPublic()
            window.location.href = url;
        } catch {
            this.error = " the latest Newsletter is no longer availible";
        }
    }, methods: {
        goBack() {
            this.$router.push("/p/view/0");
        }
    }
};
</script>
