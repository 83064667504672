<template>
  <footer class="footer">
    <div class="container"><!--
      <nav>
        <ul>
          <li>
            <a href="/">Max</a>
          </li>
          <li>
            <a href="/">
              About Us
            </a>
          </li>
        </ul>
      </nav>-->
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }} made with <i class="fa fa-heart heart MKHeart"></i> by Max
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style>
.footer {
  position: fixed !important;
}
.MKHeart {
 transform: scale(1);
 animation: heartbeat 1.5s infinite;
 color: red !important;
}

@keyframes heartbeat
{
0%
{
  transform: scale( .8 );
}
20%
{
  transform: scale( 1 );
}
40%
{
  transform: scale( .8 );
}
60%
{
  transform: scale( 1 );
}
80%
{
  transform: scale( .8 );
}
100%
{
  transform: scale( .8 );
}
}
</style>
