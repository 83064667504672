<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Editions</h4>
            <p class="category">Here you can find all editions from our newsletter. Click the desired edition you want to view or download.</p>
          </md-card-header>
          <md-card-content>
            <ordered-table></ordered-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { OrderedTable } from "@/components";

export default {
  components: {
    OrderedTable
  },
  async created() {
  }
};
</script>
