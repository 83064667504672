<template>
  <div>
    <md-table v-model="newsletters" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }" v-on:click="clicked(item)">
        <md-table-cell md-label="Edition #">#{{ item.id }}</md-table-cell>
        <md-table-cell md-label="Name">{{ item.name }}
          <md-badge v-if="item.latest" class="md-square badge-fit" md-content="latest" />
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import { useUserStore } from "../../store";

export default {
  name: "ordered-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
    showFotoMagazineOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      newsletters: []
    };
  }, async created() {
    // query API for all newsletters
    try {
        const data = await AuthService.getAllNewsletters();
        let newsletters = [];
        for (const val of data) {
          if (val['id'].includes(".")) { // if the version-id is X.Y it's a photo-magazine
            if (this.showFotoMagazineOnly) {
              newsletters.push(val);
            }
          } else if (!this.showFotoMagazineOnly) {
            newsletters.push(val);
          }
        }
        this.newsletters = newsletters;
    } catch (e) {
      console.error(e);
      console.error("Authenticated session expired, resetting states");
      useUserStore().reset();
      this.$router.push('/login');
    }
  }, methods: {
    clicked(item) {
      const newsletter_id = `${item.id}`;
      this.$router.push(`/p/view/${newsletter_id}`);
    }
  }
};
</script>

<style>
.badge-fit {
  right: 4px !important;
}

.md-table-row {
  cursor: pointer;
}
</style>